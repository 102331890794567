/*
This file will create an array of Mobile Filters that will be available when a mobile selfie is actioned.
These files are all located in ../images/mobileSvg/. There are also PNG's in there if you want to use them.
The reason they are called in this way is because React JS will create a Static cached build of the image in
the "build" folder of he App. React JS works better when it calls these static generated files.
 */

import Lightning from "../images/mobileSvg/lightning.svg";
import Happy from '../images/mobileSvg/happy.svg';
import Tentacles from "../images/mobileSvg/tentacles.svg";
import Love from '../images/mobileSvg/love.svg';
import Space from '../images/mobileSvg/space.svg';
import Cool from '../images/mobileSvg/cool.svg';
import Nature from '../images/mobileSvg/nature.svg';

const MobileFilterChoices = [
    Lightning,
    Happy,
    Tentacles,
    Love,
    Space,
    Cool,
    Nature
]

export default MobileFilterChoices;