import React from "react";
import WebcamCapture from "./components/WebCamCapture";
import { isEdge } from "react-device-detect"

function App() {
    // An alert if a user is using Edge. Some Edge browsers, for some reason don't let the "react-webcam" third-party library work.
    if(isEdge) {
        alert("This app may not work on your Microsoft Edge or Internet Explorer browser. Please use another browser or for a better experience, please use a Mobile Device.");
    }

    return (
        <div id={'videoCapture'}>
            {/* Call the Webcam Capture constant from ../components/WebcamCapture.js (line 85) */}
            <WebcamCapture/>
        </div>
    )
}

export default App;