import React from "react";

import "react-app-polyfill/stable";

const Filters = ({ image, browser }) => {
    if(browser) {
        return (
            (image !== null) && <img src={image} id={'filterImage'} alt={'Filter'} className={'webcam--filter'} width={window.innerWidth} height={window.innerHeight}/>
        )
    } else {
        if(image !== null && !document.getElementById('filterImage')) {
            let div = document.querySelector('div.styles_Camera_2YrFaFW5xnMVICdsVnfUvt');
            div.id = 'selfieMachine';
            let imageSrc = document.createElement('img');
            imageSrc.src = image;
            imageSrc.id = 'filterImage';
            imageSrc.alt = 'Filter';
            imageSrc.className = 'webcam--filter';
            imageSrc.width = window.innerWidth;
            imageSrc.height = window.innerHeight;
            div.prepend(imageSrc);
        }

        return true;
    }
}

export default Filters;