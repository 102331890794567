/*
This file will create an array of Desktop Filters that will be available when a desktop selfie is actioned.
These files are all located in ../images/desktopPng/. There are also SVG's in there if you want to use them.
The reason they are called in this way is because React JS will create a Static cached build of the image in
the "build" folder of he App. React JS works better when it calls these static generated files.
 */
import Lightning from "../images/desktopPng/lightning.png";
import Happy from '../images/desktopPng/happy.png';
import Tentacles from "../images/desktopPng/tentacles.png";
import Love from '../images/desktopPng/love.png';
import Space from '../images/desktopPng/space.png';
import Cool from '../images/desktopPng/cool.png';
import Nature from '../images/desktopPng/nature.png';

const DesktopFilterChoices = [
    Lightning,
    Happy,
    Tentacles,
    Love,
    Space,
    Cool,
    Nature
]

export default DesktopFilterChoices;