// The tools to make this app work.
import React, {useCallback, useEffect, useRef, useState} from "react";
import Webcam from "react-webcam"; // visit: https://github.com/mozmorris/react-webcam
import mergeImages from "merge-images"; // visit: https://github.com/lukechilds/merge-images
import {isDesktop, isMobile, isTablet, isFirefox} from "react-device-detect"; // visit: https://github.com/duskload/react-device-detect

// Font Awesome Icons called from the node_modules library folder.
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCameraRetro,
    faChevronLeft,
    faCircleNotch,
    faDownload
} from "@fortawesome/free-solid-svg-icons";

// All Library and Styling Files
import '../sass/Camera.scss';
import ChangeFilters from '../images/SVG/change-filter.svg';
import Filters from "../lib/Filters";
import MobileFilterChoices from "../lib/MobileFiltersChoice";
import DesktopFilterChoices from "../lib/DesktopFiltersChoice";

// A calculation to determine the aspect ratio of the screen. This is used to ensure that the photo and camera are
// optimised to work to the resolution of the screen giving an optimised result when a Selfie is taken. See Line 55 to
// see where the aspectRatio variable is used.
let aspectRatio = window.innerWidth/window.innerHeight;
// If mobile and not localhost switch the aspect ratio around to fit the mobile portrait screen.
if ( isMobile && window.location.hostname !== "localhost" ) {
    aspectRatio = window.innerHeight/window.innerWidth;
}

// Get filters dependent on the device used so the user can make the best choice.
let FilterChoices = DesktopFilterChoices;
if ( isMobile || aspectRatio < 1 ) {
    FilterChoices = MobileFilterChoices;
}

// An alert message to let a user know that the app works better on mobiles, rather than desktops.
if ( isDesktop || isTablet ) {
    alert('Our selfie app is better on a mobile device. If you wish to use your phone instead, close this page and visit it on your device.');
}

// This calculation is used to ensure that the maximum aspectWidth is used for the camera constraints. For more
// information on the react-webcam library, visit: https://github.com/mozmorris/react-webcam
const aspectWidth = Math.round(4320 * aspectRatio);

// In order to use the camera, you need to set constraints for the camera size. This is where our calculations above
// will be used.
let videoConstraints = {};
if(isFirefox) {
    // todo: In FireFox, there seems to be an issue with the constraints. It makes it square so this needs looking at.
    videoConstraints = {
        width: window.innerWidth,
        height: window.innerHeight,
        facingMode: "user"
    };
} else {
    videoConstraints = {
        width: {max: aspectWidth}, // Dependent on orientation, this figure could be bigger or smaller than height. See Line 39
        height: {max: 4320},
        aspectRatio: aspectRatio, // Calculation to determine the aspectRatio (i.e. 16:9 = 0.5625) from Lines 20-23.
        facingMode: "user"
    };
}

// This function is to change the Desktop/Mobile filter into a Canvas Data Image (data:img) to assist in the merging of
// the filter and the photo when the user clicks, take a photo.
function imageToDataUri(img, width, height) {
    const image = new Image(width, height);
    image.src = img;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(image, 0, 0, width, height);

    return canvas.toDataURL('image/png', 1.0);
}

// The camera button always goes off center, so this function pulls it back in.
function repositionCameraBtn(buttonRef) {
    if(document.getElementById('snapIt')) {
        let width = parseInt(buttonRef.current.offsetWidth) / 2;
        buttonRef.current.style.marginLeft = "-" + width + "px";
    }
}

// Go back to the website
function goBack() {
    if (typeof window !== "undefined") {
        return window.history.back();
    }
}

// The constant to make the Selfie App work.
const WebCamCapture = () => {
    const webcamRef = useRef(null);
    const buttonRef = useRef(null);

    const [imgSrc, setImgSrc] = useState(null); // Used to create and callback a photo state when the camera takes a photo.
    const [filterOpen, setFilterOpen] = useState(true); // Used to display the filter options when the button is clicked.
    const [choiceImg, setChoiceImg] = useState(null); // Used to create and callback a filter state when the user chooses a filter for their selfie.
    const [loading, setLoading] = useState(false); // Sometimes the photo merging can take a while so this creates a loading message.

    const capture = useCallback(
        () => {
            // SET A LOADING SCREEN
            setLoading(true);

            // Lines 101 to 110 calculate the height and width of the photo that was taken to push these to the "getScreenshot" function on Line 112
            // and also to PNG to Canvas Data URI function from Line 62 to Line 113.
            let imgWidth = webcamRef.current.stream.getVideoTracks()[0].getSettings().width;
            let imgHeight = webcamRef.current.stream.getVideoTracks()[0].getSettings().height;

            if ( isMobile && window.location.hostname !== "localhost" ) {
                if(aspectRatio > 1) {
                    imgWidth = imgHeight / aspectRatio;
                } else {
                    imgHeight = imgWidth / aspectRatio;
                }
            }

            const imageSrc = webcamRef.current.getScreenshot({ width: imgWidth, height: imgHeight });
            const overlaySrc = imageToDataUri(choiceImg, imgWidth, imgHeight);

            // Once both Data URI image codes are ready, they need to be merged using this function from "merge-images"
            // library. Visit: https://github.com/lukechilds/merge-images
            mergeImages([
                {
                    src: imageSrc,
                    width: imgWidth,
                    height: imgHeight
                },
                {
                    src: overlaySrc,
                    width: imgWidth,
                    height: imgHeight
                }
            ], {
                quality: 1
            }).then(b64 => setImgSrc(b64)).catch(error => alert(error));
            // Once this is done, it will set a state for imgSrc which will change the screen and create a downloadable
            // image.
        },
        [choiceImg]
    );

    // This constant resets all the states to it's original values and allows the user to retake a photo.
    const retakePhoto = useCallback(
        () => {
            setImgSrc(null);
            setChoiceImg(null);
            setLoading(false);
            // setFilterOpen(true); // todo - this can be re-added if the client wants the filter automatically open when taking another photo.
            repositionCameraBtn(buttonRef);
        }, []
    )

    // This function repositions the camera button on load.
    useEffect(() => {
        repositionCameraBtn(buttonRef);
    }, [])

    return imgSrc !== null ? (
        <>
            {/* Show the photo when the photo is taken */}
            <div className={'photo--container'}>
                <button type={'button'} onClick={() => goBack()} className={'webcam--filters--back-button'}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <div className={'photo--options'}>
                    <ul>
                        {/* Re-take the photo */}
                        <li onClick={() => retakePhoto()}>
                            <FontAwesomeIcon icon={faCameraRetro} />
                        </li>
                        {/* Download the photo */}
                        <li>
                            <a href={imgSrc} download={'Platfform Selfie'}>
                                <FontAwesomeIcon icon={faDownload} />
                            </a>
                        </li>
                    </ul>
                </div>
                {/* Show the Data URI merged image */}
                <img src={imgSrc} alt={'Selfie'} className={'w-100'} />
            </div>
        </>
    ) : (
        <>
            {/* The page to take the photos */}
            <div className={'webcam--container'}>
                {/* Choose a filter popup */}
                <div className={(filterOpen === true) ? 'webcam--filters webcam--filters--open' : 'webcam--filters'}>
                    <div className={'container-fluid'}>
                        <div className={'row py-4'}>
                            {FilterChoices.map((value, index) => {
                                return(
                                    <div key={index} className={'col-6 col-md-4 col-lg-3 mb-4'} onClick={event => {
                                        setChoiceImg(event.target.src);
                                        setFilterOpen(false);
                                    }}>
                                        <img src={value} alt={'Filter Choice'} className={'webcam--filters--option w-100 border border-white'} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <button type={'button'} onClick={() => goBack()} className={'webcam--filters--back-button'}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                {/* Open the filter popup */}
                <button type={'button'} onClick={() => setFilterOpen(filterOpen => !filterOpen)} className={'webcam--filters--button'}>
                    <img src={ChangeFilters} alt={'Change Filters'} />
                </button>
                {/* Once the filter has been opened, overlay it over the camera */}
                <Filters image={choiceImg} browser={true} />
                {/* The webcam that shows you what the camera sees */}
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotQuality={1}
                    videoConstraints={videoConstraints}
                    screenshotFormat={'image/png'}
                    mirrored={true}
                />
                {/* Take the photo */}
                <button id={'snapIt'} onClick={capture} className={'webcam--photo'} ref={buttonRef}>
                    <FontAwesomeIcon icon={faCameraRetro} size={'2x'} color={'#FFFFFF'} />
                </button>
                {/* If it takes a while for the photo to be processed, this will show as a loading screen */}
                <div className={(loading === true) ? 'webcam--loading webcam--loading__active' : 'webcam--loading'}>
                    <div className={'webcam--loading--content'}>
                        <p>Hold your pose...</p>
                        <FontAwesomeIcon icon={faCircleNotch} spin={true} color={'#FFFFFF'} size={'2x'} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default WebCamCapture;